import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { User } from 'components/Provider'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Panel from 'components/Base/Panel'
import Card from 'components/Base/Card'
import MediaCard from 'components/Base/MediaCard'
import Dialog from 'components/Base/Dialog'
import EditUserNameDialog from 'components/Person/EditUserNameDialog'
import CustomerRegistrationDialog from 'components/Person/CustomerRegistrationDialog'
import UpdateMobileDialog from 'components/Person/UpdateMobileDialog'
import EditPositionDialog from 'components/Person/EditPositionDialog'
import UpdateAvatarDialog from 'components/Person/UpdateAvatarDialog'
import LogoutDialog from 'components/Person/LogoutDialog'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import UserInfo from 'components/Person/UserInfo'
import RecommendCard from 'components/Person/RecommendCard'
import BaseInfo from 'components/Person/BaseInfo'
import WeChatBound from 'components/Person/WeChatBound'
import Professional from 'components/Person/Professional'
import UserInfoMobile from 'components/Person/UserInfoMobile'
import MobileCenterMenu from 'components/Person/MobileCenterMenu'
import MobileMenuList from 'components/Person/MobileMenuList'
import { OptionProps } from 'components/Base/Select'
import DialogCertifiedInfo from 'components/Person/DialogCertifiedInfo'
import Logout from 'components/Person/Logout'
import { isPc } from 'utils/utils'
import {
  fetchUGDLastUEDemo,
  fetchUGDCourse,
  fetchUGDLastPVDemo,
  fetchUGDReport,
  fetchUGDOptimization,
  fetchIndustries,
  updateAvatar,
  updateUsers,
  addUserFavorite,
} from 'api/user'
import { getUserCertification, deleteUserCertification } from 'api/index'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'swiper/swiper-bundle.css'
import * as styles from './index.module.less'

import avatarPng from 'assets/images/account/avatar.png'
import recommendQrcode from 'assets/images/account/recommend-qrcode.png'
import IconBrowse from 'assets/images/account/icon-browse.svg'
import LoginWithFull from 'components/LoginWithFull'
import Favorites from './Favorites'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export interface UGD {
  id: number
  createTime: string
  updatedTime: string
  mainCoverUrl: string
  title: string
  description: string
  infoLink: string
  contentType: string
  industry: string
}

export interface Industry {
  id: number
  industry_key: string
  industry_value: string
}

const Person: React.FC = (props) => {
  const [mSwiper, setSwiper] = useState<SwiperCore>()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [visibleUserName, setVisibleUserName] = useState(false)
  const [visibleRegistration, setVisibleRegistration] = useState(false)
  const [visibleMobile, setVisibleMobile] = useState(false)
  const [visiblePosition, setVisiblePosition] = useState(false)
  const [visibleAvatar, setVisibleAvatar] = useState(false)
  const [visibleDialogCertified, setVisibleDialogCertified] = useState(false)
  const [visibleReason, setVisibleReason] = useState<boolean>(false)
  const [courses, setCourses] = useState<UGD[]>()
  const [demoList, setDemoList] = useState<UGD[]>()
  const [pvDemo, setPVDemo] = useState<UGD[]>()
  const [reportList, setReportList] = useState<UGD[]>()
  const [selection, setSelection] = useState<UGD>()
  const [industryList, setIndustryList] = useState<OptionProps[]>()
  const [isUserUpdate, setIsUserUpdate] = useState<boolean>(false)
  const [client, setClient] = useState<string>()
  const [certifiedInfo, setCertifiedInfo] = useState<any>()
  const [confirmDialog, setConfirmDialog] = useState<any>({
    className: 'person-dialog-confirm',
    visible: false,
    title: '提示',
    width: 300,
    content: <></>,
    onClose: () => {},
    cancelText: '取消',
    onCancel: () => {},
    confirmText: '重置认证',
    onConfirm: () => {},
  })
  const [userNameError, setUserNameError] = useState('')

  /** 是否登陆，控制展示蒙板登录模块 */
  const [isLogin, setIsLogin] = useState(false)
  /** 请求用户数据的loading */
  const [loading, setLoading] = useState(true)
  /** 更新收藏内容 */
  const [updataKey, setUpdataKey] = useState<number>(0)

  const LogoutRef = useRef(null)

  const handleClickTab = (index: number) => {
    mSwiper?.slideTo(index)
  }

  useEffect(() => {
    if (isPc()) {
      setClient('pc')
    } else {
      setClient('mobile')
    }
    // 视频课程
    fetchUGDCourse().then(
      (res) => {
        setCourses(res.data)
      },
      (e) => {
        console.log(e.response)
      },
    )
    // 最近体验demo
    fetchUGDLastUEDemo().then(
      (res) => {
        setDemoList(res.data)
      },
      (e) => {
        console.log(e)
      },
    )
    // 最近浏览的内容
    fetchUGDLastPVDemo().then(
      (res) => {
        setPVDemo(res.data)
      },
      (e) => {
        console.log(e.response)
      },
    )
    // 研究报告/白皮书
    fetchUGDReport().then(
      (res) => {
        setReportList(res.data)
      },
      (e) => {
        console.log(e.response)
      },
    )
    // 为您精选
    fetchUGDOptimization().then(
      (res) => {
        setSelection(res.data[0])
      },
      (e) => {
        console.log(e.response)
      },
    )
    // 行业列表
    fetchIndustries().then(
      (res) => {
        let industryOptions: OptionProps[] = []
        res.data.forEach((item: Industry) => {
          industryOptions.push({
            label: item.industry_value,
            value: item.industry_value,
          })
        })
        setIndustryList(industryOptions)
      },
      (e) => {
        console.log(e.response)
      },
    )
    userCertifiedInfo()

    if (!isPc()) {
      document.body.className = document.body.className + 'person-mb-F5F5F5'
    }
  }, [])

  // 获取用户企业认证信息
  const userCertifiedInfo = () => {
    getUserCertification()
      .then((res: any) => {
        const data = res.data
        if (!Object.keys(data).length) return
        setCertifiedInfo(data)
      })
      .catch(() => {
        setCertifiedInfo(undefined)
      })
  }

  // 修改头像
  const onAvatarConfirm = (image: string) => {
    updateAvatar({
      data: image,
    }).then(
      (res) => {
        setVisibleAvatar(false)
        setIsUserUpdate(!isUserUpdate)
      },
      (e) => {
        console.log('updateAvatar=======', e.response)
      },
    )
  }

  // 修改姓名
  const onUserNameConfirm = (userName?: string) => {
    setUserNameError('')
    updateUsers({
      userName,
    }).then(
      (res) => {
        setVisibleUserName(false)
        setIsUserUpdate(!isUserUpdate)
      },
      (e) => {
        console.log('updateAvatar=======', e.response)
        const {
          data: { error },
        } = e.response
        setUserNameError(error)
      },
    )
  }

  // 修改职业信息
  const onPositionConfirm = (group_name?: string, position?: string, industry?: string) => {
    updateUsers({
      companyName: group_name,
      position,
      industry,
    }).then(
      (res) => {
        setVisiblePosition(false)
        setIsUserUpdate(!isUserUpdate)
      },
      (e) => {
        console.log('updateAvatar=======', e.response)
      },
    )
  }

  // 提交认证成功
  const onCertifiedSubmit = (data: any) => {
    setCertifiedInfo(data)
    setVisibleRegistration(false)
    setTimeout(() => {
      setVisibleDialogCertified(true)
    }, 500)
  }

  // 点击重新认证
  const handleReCertified = () => {
    setConfirmDialog({
      ...confirmDialog,
      visible: true,
      title: '你要重置认证吗？',
      content: <>选择重置认证将清空当前的认证状态重新进入认证流程</>,
      onClose: () => {
        setConfirmDialog({
          ...confirmDialog,
          visible: false,
        })
      },
      onCancel: () => {
        setConfirmDialog({
          ...confirmDialog,
          visible: false,
        })
      },
      onConfirm: () => {
        // 确认重置认证
        deleteUserCertification()
          .then(() => {
            userCertifiedInfo()
            setConfirmDialog({
              ...confirmDialog,
              visible: false,
            })
            setVisibleDialogCertified(false)
            setVisibleRegistration(true)
            toast.info('已重置认证', {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          })
          .catch((e) => {
            console.log(e?.response)
            toast.info(e?.response?.data?.error, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          })
      },
    })
  }
  // 取消注销
  const handleClickCancel = () => {
    setVisibleReason(false)
    LogoutRef && LogoutRef?.current?.fallback(0)
    mSwiper?.slideTo(1)
  }
  const onConfirmLogout = () => {
    setVisibleReason(false)
    LogoutRef && LogoutRef?.current?.fallback(2)
  }
  // 点击注销
  const onClickLogout = () => {
    mSwiper?.slideTo(2)
  }
  // 放弃注销
  const handleAbandonLogout = () => {
    mSwiper?.slideTo(1)
  }

  // 从url获取收藏参数
  const parseUrlParams = () => {
    const params = new URLSearchParams(window.location.search)
    return {
      url: params.get('url'),
      title: params.get('title'),
      pageId: params.get('page_id'),
    }
  }

  // SS-125 收藏功能，获取url中的参数
  useEffect(() => {
    const { url, title, pageId } = parseUrlParams()
    if (!isLogin || !url || !title) {
      return
    }
    
    addUserFavorite({
      url: decodeURIComponent(url),
      title: decodeURIComponent(title),
      pageId,
    }).then(() => {
      setUpdataKey((k) => k + 1)
      // 锚点到我的收藏
      document.getElementById('favoritesWrapper')?.scrollIntoView();
    })
    window.history.pushState({}, '', window.location.pathname)
  }, [isLogin])

  const _renderPc = () => {
    return (
      <>
        <main
          className={classnames({
            [styles.MainContainer]: true,
            [styles.blur]: !isLogin,
          })}
        >
          <div className="tab" key="tab">
            <ul className="tab-menu">
              <li className={classnames(activeIndex === 0 ? 'active' : '')} onClick={() => handleClickTab(0)}>
                总览
              </li>
              <li
                className={classnames(activeIndex === 1 ? 'active' : '', activeIndex === 2 ? 'active' : '')}
                onClick={() => handleClickTab(1)}
              >
                个人资料
              </li>
            </ul>
          </div>
          <div className="tab-content" key="tab-content">
            <Swiper
              pagination={false}
              onSwiper={(swiper) => {
                setSwiper(swiper)
                swiper.slideTo(activeIndex)
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
              autoHeight={true}
              allowTouchMove={isPc() ? false : true}
              autoplay={false}
            >
              <SwiperSlide className="overview-content">
                <div className="row">
                  <UserInfo onClickEdit={() => handleClickTab(1)} />
                  <RecommendCard qrcode={recommendQrcode} bg={selection?.mainCoverUrl} />
                </div>
                <Panel className="footprint-wrap" title="我的足迹">
                  <Card title="最近体验的 Demo">
                    {(demoList === undefined || demoList.length === 0) && (
                      <p className="no-demo-info">
                        您尚未体验过任何 Demo，马上开启免费体验之旅吧！
                        <br />
                        为您推荐 ——
                      </p>
                    )}
                    {demoList?.map((item, index) => (
                      <MediaCard
                        icon={item.mainCoverUrl || IconBrowse}
                        title={item.title}
                        desc={item.description}
                        buttonText="继续体验"
                        buttonIcon="arrow"
                        href={item.infoLink}
                        key={index}
                      />
                    ))}
                  </Card>
                  <Card title="最近浏览的内容">
                    {pvDemo?.slice(0, 2)?.map((item, index) => (
                      <MediaCard
                        icon={item.mainCoverUrl || IconBrowse}
                        iconSize={50}
                        desc={item.title}
                        buttonText="继续浏览"
                        buttonIcon="arrow"
                        href={item.infoLink}
                        key={index}
                      />
                    ))}
                  </Card>
                </Panel>
                <Panel
                  className="brilliant-wrap"
                  title="充分使用您的账户权限，以下精彩不容错过"
                  subTitle="30+ 深度研究报告/白皮书，100+ 经典视频课程"
                >
                  <Card title="研究报告/白皮书">
                    {reportList?.map((item, index) => (
                      <MediaCard
                        thumb={item.mainCoverUrl}
                        title={item.title}
                        desc={item.description}
                        buttonText="下载"
                        buttonIcon="download"
                        descLineSize={2}
                        href={item.infoLink}
                        key={index}
                      />
                    ))}
                    <div className="more-button-group">
                      <a
                        href="https://www.sensorsdata.cn/school/library"
                        className="more-button arrow"
                        target={'_blank'}
                      >
                        查看更多
                      </a>
                    </div>
                  </Card>
                  <Card title="视频课程">
                    {courses?.map((item, index) => (
                      <MediaCard
                        thumb={item.mainCoverUrl}
                        title={item.title}
                        desc={item.description}
                        buttonText="观看"
                        buttonIcon="camera"
                        descLineSize={2}
                        href={item.infoLink}
                        key={index}
                      />
                    ))}
                    <div className="more-button-group">
                      <a
                        href="https://www.sensorsdata.cn/market/overview.html"
                        className="more-button arrow"
                        target={'_blank'}
                      >
                        查看更多
                      </a>
                    </div>
                  </Card>
                </Panel>

                <Favorites updataKey={updataKey}></Favorites>
                {/* 签约客户登记表 PC端: 计划等到学堂pc版弄好后 再开放 */}
                {/* <div className="customer-register-card">
                  <img src={IconRegister} alt="" className="icon-register" />
                  <p>如果您是神策的签约客户，登记您的公司名称和公司邮箱，我们核实后会为您开放签约客户专属板块哦！</p>
                  <Button btnType="default" onClick={() => {
                    if(certifiedInfo) {
                      setVisibleDialogCertified(true);
                    } else {
                      setVisibleRegistration(true);
                    }
                  }}>客户签约登记</Button>
                </div> */}
              </SwiperSlide>
              <SwiperSlide className="personal-info-content">
                <BaseInfo
                  className="base-info-container"
                  avatar={avatarPng}
                  onClickAvatar={() => setVisibleAvatar(true)}
                  onClickMobile={() => setVisibleMobile(true)}
                  onClickUserName={() => setVisibleUserName(true)}
                  onClickLogout={onClickLogout}
                />
                <div className="personal-info-content--right">
                  <WeChatBound onBindSuccess={() => setIsUserUpdate(!isUserUpdate)} />
                  <Professional onClickEdit={() => setVisiblePosition(true)} />
                </div>
              </SwiperSlide>
              <SwiperSlide className="logout-content">
                <Logout ref={LogoutRef} onOpen={() => setVisibleReason(true)} abandonLogout={handleAbandonLogout} />
              </SwiperSlide>
            </Swiper>
          </div>
        </main>
        {/* 编辑我的姓名 */}
        <EditUserNameDialog
          visible={visibleUserName}
          onClose={() => setVisibleUserName(false)}
          onSubmit={onUserNameConfirm}
          errorMsg={userNameError}
        />
        {/* 签约客户登记表 */}
        <CustomerRegistrationDialog
          visible={visibleRegistration}
          onClose={() => setVisibleRegistration(false)}
          onSubmit={onCertifiedSubmit}
        />
        {/* 签约客户提交状态 */}
        <DialogCertifiedInfo
          visible={visibleDialogCertified}
          data={certifiedInfo}
          onClose={() => setVisibleDialogCertified(false)}
          onCancel={() => handleReCertified()}
        />
        {/* 请输入更换后的手机号码 */}
        <UpdateMobileDialog
          visible={visibleMobile}
          onClose={() => setVisibleMobile(false)}
          onSuccess={() => {
            setVisibleMobile(false)
            setIsUserUpdate(!isUserUpdate)
          }}
        />
        {/* 编辑职位信息 */}
        <EditPositionDialog
          visible={visiblePosition}
          onClose={() => setVisiblePosition(false)}
          onSubmit={onPositionConfirm}
          industryOptions={industryList as { value: string; label: string }[]}
        />
        {/* 您新头像的位置和大小 */}
        <UpdateAvatarDialog
          visible={visibleAvatar}
          onClose={() => setVisibleAvatar(false)}
          onConfirm={onAvatarConfirm}
        />
        {/* dialog confirm */}
        <Dialog
          className={confirmDialog.className}
          visible={confirmDialog.visible}
          width={confirmDialog.width}
          title={confirmDialog.title}
          onClose={confirmDialog.onClose}
          onCancel={confirmDialog.onCancel}
          cancelText={confirmDialog.cancelText}
          onConfirm={confirmDialog.onConfirm}
          confirmText={confirmDialog.confirmText}
        >
          {confirmDialog.content}
        </Dialog>
        {/* 确认注销 */}
        <LogoutDialog visible={visibleReason} onClose={handleClickCancel} onConfirmLogout={onConfirmLogout} />
      </>
    )
  }

  const _renderMb = () => {
    return (
      <>
        <div className={styles.MainContainer}>
          <UserInfoMobile />
          <MobileCenterMenu className="mobile-center-menu" />
          <MobileMenuList
            className="mobile-menu-list"
            onClickCertified={() => {
              if (certifiedInfo) {
                setVisibleDialogCertified(true)
              } else {
                setVisibleRegistration(true)
              }
            }}
          />
          {/* 签约客户登记表 移动端 */}
          <CustomerRegistrationDialog
            visible={visibleRegistration}
            onClose={() => setVisibleRegistration(false)}
            onSubmit={onCertifiedSubmit}
          />
          {/* 签约客户提交状态 */}
          <DialogCertifiedInfo
            visible={visibleDialogCertified}
            data={certifiedInfo}
            onClose={() => setVisibleDialogCertified(false)}
            onCancel={() => handleReCertified()}
          />
          {/* dialog confirm */}
          <Dialog
            className={confirmDialog.className}
            visible={confirmDialog.visible}
            width={confirmDialog.width}
            title={confirmDialog.title}
            onClose={confirmDialog.onClose}
            onCancel={confirmDialog.onCancel}
            cancelText={confirmDialog.cancelText}
            onConfirm={confirmDialog.onConfirm}
            confirmText={confirmDialog.confirmText}
          >
            {confirmDialog.content}
          </Dialog>
        </div>
      </>
    )
  }

  const onUserInfo = (data: User | null) => {
    if (data === null) {
      // 展示登陆
      setIsLogin(false)
      // window.location.href = '/'
    } else {
      setIsLogin(true)
    }
    setLoading(false)
  }

  return (
    <Layout {...props} footerClassName={styles.PersonFooter} userUpdate={isUserUpdate} onUserInfo={onUserInfo}>
      <Seo title="个人中心 | 神策数据" description="" keywords="" saTitle=""></Seo>
      {loading ? (
        <div id="loadingDiv" style={{ height: '100vh' }}></div> // 简易骨架屏
      ) : (
        <>
          {client === 'pc' && _renderPc()}
          {client === 'mobile' && _renderMb()}
          {!isLogin && <LoginWithFull />}
        </>
      )}

      <ToastContainer />
    </Layout>
  )
}

export default Person
