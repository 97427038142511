import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Input from 'components/Base/Input'
import Select from 'components/Base/Select'
import Form, { FormItem } from 'components/Base/Form'
import Dialog from 'components/Base/Dialog'
import { Container } from './index.module.less'
import { getEnterprisesList, postUserCertification } from 'api/index'
import { isEmail } from 'utils/validate'

interface Params {
  visible: boolean
  onSubmit?: (data: any) => void
  onClose?: React.MouseEventHandler<HTMLElement> | undefined
}

const CustomerRegistrationDialog: React.FC<Params> = (params) => {
  const [state, setState] = useState<any>({
    company: '',
    companyId: '',
    emailMatchErr: false,
    emaliErr: '',
  })
  const [enterpriseList, setEnterpriseList] = useState<any[]>([])
  const [certifiedInfo, setCertifiedInfo] = useState<any>()
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [selectCompany, setSelectCompany] = useState<any>(undefined)
  const { visible, onClose, onSubmit } = params

  // 企业邮箱及匹配公司
  const handleEmail = (val: string) => {
    setEmail(val)

    if (isEmail(val)) {
      const index = val.lastIndexOf('@')
      const suffix = val.substring(index + 1)
      getCompanyInfo(suffix)
    } else {
      setState({ ...state, company: '', companyId: '', emailMatchErr: false, emaliErr: '' })
      setSelectCompany(undefined)
      setEnterpriseList([])
    }
  }

  // 获取公司信息
  const getCompanyInfo = (suffix: string) => {
    getEnterprisesList(suffix)
      .then((res: any) => {
        let data = res.data
        if (!data || !data?.length) {
          setState({
            ...state,
            company: '',
            companyId: '',
            emailMatchErr: true,
            emailErr: '！您所在的企业暂未提交企业邮箱信息，请联系客户成功经理提交企业邮箱信息开通线上认证方式',
          })
          setEnterpriseList([])
          return
        }
        setState({ ...state, emailMatchErr: false, companyErr: '' })
        data.forEach((item: any) => {
          item.label = item.name
          item.value = item.objId
        })
        if (data?.length === 1) {
          const { name = '', objId = '' } = data[0] || {}
          setState({ ...state, company: name, companyId: objId })
          setSelectCompany(data[0])
        }
        setEnterpriseList(data)
      })
      .catch(() => {
        setState({ ...state, emailMatchErr: true })
      })
  }

  // 表单验证
  const validate = () => {
    if (name === '') return setState({ ...state, nameErr: '！请填写姓名后提交' })
    if (email === '') return setState({ ...state, emailErr: '！请填写企业邮箱后提交' })
    if (!isEmail(email)) return setState({ ...state, emailErr: '！请填写正确格式的邮箱信息' })

    if (state.emailMatchErr) {
      return setState({
        ...state,
        emailErr: '！您所在的企业暂未提交企业邮箱信息，请联系客户成功经理提交企业邮箱信息开通线上认证方式',
      })
    }
    if (!state.companyId)
      return enterpriseList?.length > 1 && setState({ ...state, companyErr: '！请选择系统匹配的公司信息' })
    setState({ ...state, nameErr: '', emailErr: '', companyErr: '' })
    return true
  }

  // 提交认证
  const handleSubmit = () => {
    if (!validate()) return
    // Loading.show({ bgColor: 'transparent' });
    const params = {
      certifiedName: name,
      enterpriseEmail: email,
      enterpriseObjId: state.companyId,
      source: '官网客户认证', // 认证来源
    }
    postUserCertification(params)
      .then((res: any) => {
        let data = res.data
        setCertifiedInfo(data)
        onSubmit && onSubmit(data)
      })
      .catch((res: any) => {
        let data = res.response.data
        toast.error(data.error, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  // 选择公司
  const onSelectCompany = (item: any) => {
    const { name = '', objId = '' } = item
    setState({ ...state, company: name, companyId: objId })
    setSelectCompany(item)
  }

  return (
    <Dialog
      className={Container}
      visible={visible}
      title="签约客户登记表"
      onClose={onClose}
      hideCancel
      onConfirm={handleSubmit}
      confirmText="提交"
    >
      <Form>
        <FormItem error={state.nameErr}>
          <Input
            placeholder="请输入您的名字"
            onFocus={() => setState({ ...state, nameErr: '' })}
            onChange={(e) => setName(e.target.value)}
          />
        </FormItem>
        <FormItem error={state.emailErr}>
          <Input
            placeholder="请输入您的企业邮箱"
            onBlur={(e: any) => handleEmail(e.target.value)}
            onFocus={() => setState({ ...state, emailErr: '' })}
            onChange={() => {
              setState({ ...state, company: '', companyId: '', companyErr: '', emailMatchErr: false })
              setSelectCompany(undefined)
              setEnterpriseList([])
            }}
          />
        </FormItem>
        <FormItem error={state.companyErr}>
          {/* <Input placeholder="请输入您的公司" /> */}
          <Select
            placeholder={enterpriseList?.length > 0 ? '请选择您的公司' : '系统将为您自动匹配公司信息'}
            value={selectCompany}
            options={enterpriseList}
            onChange={(val: any) => onSelectCompany(val)}
          />
        </FormItem>
      </Form>
    </Dialog>
  )
}

export default CustomerRegistrationDialog
