import React, { useState } from "react"
import 'react-toastify/dist/ReactToastify.css';
import Dialog from 'components/Base/Dialog'
import { Container } from './index.module.less'

import IconSendSuccess from 'assets/images/account/icon-send-success.svg';
import IconAuth from 'assets/images/account/icon-auth.svg';
import Button from "components/Base/Button";

interface Params {
  visible: boolean;
  data?: any;
  onClose?: React.MouseEventHandler<HTMLElement> | undefined;
  onCancel?: React.MouseEventHandler<HTMLElement> | undefined;
}

const DialogCertifiedInfo: React.FC<Params> = (params) => {
  const { 
    visible,
    data,
    onClose,
    onCancel
   } = params;

  return (
    <Dialog 
      className={Container}
      visible={visible}
      title="签约客户登记表"
      onClose={onClose}
      onCancel={onCancel}
      cancelText="重新认证"
      onConfirm={onClose}
      confirmText="确认">
      {data?.certifiedStatus ? <div className="DialogCertifiedInfo_body auth-success">
        <img src={IconAuth} alt="" className="icon icon-auth" />
        <p>恭喜你已成功认证成为神策产品用户<br />认证企业：{data?.enterprise?.name}</p>
      </div> : <div className="DialogCertifiedInfo_body email-send-success">
        <img src={IconSendSuccess} alt="" className="icon icon-send-success" />
        <p>已向你的企业邮箱发送认证邮件<br />去邮箱激活后完成认证</p>
      </div>}
    </Dialog>
  );
}

export default DialogCertifiedInfo;