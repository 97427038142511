import React, { useState, useEffect, useContext, useRef } from "react"
import classnames from 'classnames'
import QRCode from 'qrcode';
import { providerContext } from '../../../components/Provider'
import {
  fetchQrcode,
  fetchQrcodeScanEvent
} from '../../../api/user'
import {
  saGetPresetProperties
} from '../../../utils/statistics'
import { Container, BindSuccessContainer } from './index.module.less'

import IconBindSuccess from 'assets/images/account/icon-bind-success.svg';
import QrcodeCommunityPng from 'assets/images/account/qrcode-community.png';

interface WeChatBoundParams {
  className?: string | undefined;
  onBindSuccess?: () => void;
}

const WeChatBound: React.FC<WeChatBoundParams> = (params) => {
  const { onBindSuccess } = params;
  const { user } = useContext(providerContext);
  const [isValid, setValid] = useState(true);
  const [bindSuccess, setBindSuccess] = useState(false);
  const [bindSuccessNum, setBindSuccessNum] = useState<number>(3);
  const numRef = useRef<number>(3);
  const timer = useRef<any>();

  useEffect(() => {
    if(user && !user?.wx8381_openid) {
      getQrcode(rollGet);
    }
  }, [user])

  // 获取微信绑定二维码
  const getQrcode = async (fn?: () => void) => {
    const distinct_id = await saGetPresetProperties('_distinct_id');
    const is_first_day = await saGetPresetProperties('$is_first_day');
    fetchQrcode({
      distinct_id,
      is_first_day,
      code_type: 'BIND_SCHOOL_MP'
    }).then(res => {
      createQrcode(res.data.url)
      setValid(true);
      fn && fn()
    }, e => {
      console.log(e.response)
    })
  }

  // 渲染微信绑定二维码
  const createQrcode = (value: string) => {
    const canvas = document.getElementById('personQrcode');
    if(canvas) {
      QRCode
        .toCanvas(canvas, value, { width: 104, margin: 0 })
        .catch(e => {
          console.log(e.message);
        });
    }
  }

  // 请求扫码状态
  const handleListenScanStatus = () => {
    fetchQrcodeScanEvent().then(res => {
      const { data: reply } = res;
      if (reply.status === 200) {
        if (reply.data.has_wx8381_openid) {
          cleanTimer();
          // reply.data.has_phone 是否绑定手机号
          // reply.data.has_wx8381_openid 微信绑定的手机号
          // reply.data.wx_bind_phone 微信绑定的手机号
          // reply.data.wx_bind_phone_sms_code 手机验证码
          setBindSuccess(true);
          let timmer = setInterval(() => {
            if(numRef.current > 0) {
              numRef.current -= 1;
              setBindSuccessNum(numRef.current);
            } else {
              clearInterval(timmer);
              setBindSuccess(false);
            }
          }, 1000);
          onBindSuccess && onBindSuccess();
        }
      } else if (reply.status === 410) {
        // 二维码已过期
        setValid(false);
        cleanTimer();
      }
    }, e => {
      cleanTimer();
    });
  }

  // 清除微信扫码监听
  const cleanTimer = () => {
    timer && timer.current && clearInterval(timer.current);
  }

  // 轮询监听扫码状态
  let rollGet = () => {
    cleanTimer();
    timer.current = setInterval(() => {
      handleListenScanStatus();
    }, 1000)
  }

  // 刷新二维码
  const handleRefresh = () => {
    getQrcode(rollGet);
  }

  if(bindSuccess) {
    return (
      <div className={classnames(Container, BindSuccessContainer, params.className)}>
        <div className="bind-success--top">
          <img src={IconBindSuccess} alt="" />
        </div>
        <div className="bind-success--bottom">
          <p>恭喜您，微信绑定成功，解锁加入社群的福利！{bindSuccessNum} s</p>
        </div>
      </div>
    );
  }

  if(user?.wx8381_openid) {
    // 已绑定微信
    return (
      <div className={classnames(Container, params.className)}>
        <div className="wechat-bound--left">
          <h3 className="wechat-bound--title">加入社群，海量内容免费领</h3>
          <p className="wechat-bound--desc">微信扫描右侧二维码，添加神策数据小数点，进入您喜欢的社群<br />更丰富的职场技能提升资料，更深入的业务场景交流，等你来</p>
        </div>
        <div className="wechat-bound--right">
          <div className="qrcode-wrap">
            <div className="qrcode-icon top-left"></div>
            <div className="qrcode-icon top-right"></div>
            <div className="qrcode-icon bottom-left"></div>
            <div className="qrcode-icon bottom-right"></div>
            <div className="qrcode-content">
              <div id="personQrcode">
                <img src={QrcodeCommunityPng} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // 未绑定微信
    return (
      <div className={classnames(Container, params.className)}>
        <div className="wechat-bound--left">
          <h3 className="wechat-bound--title">绑定微信，解锁更多隐藏福利</h3>
          <p className="wechat-bound--desc">微信扫描右侧二维码进行绑定，<br />开启更便捷安全的登录、接收课程开播等重要通知，解锁社群的海量免费内容！</p>
        </div>
        <div className="wechat-bound--right">
          <div className="qrcode-wrap">
            <div className="qrcode-icon top-left"></div>
            <div className="qrcode-icon top-right"></div>
            <div className="qrcode-icon bottom-left"></div>
            <div className="qrcode-icon bottom-right"></div>
            <div className="qrcode-content">
              <canvas id="personQrcode"></canvas>
            </div>
            {!isValid && <div className="passed-float" onClick={handleRefresh}>
              <span>二维码已失效</span>
              <span>点击刷新</span>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default WeChatBound;