import React, { useContext } from "react"
import classnames from 'classnames'
import { providerContext } from '../../../components/Provider'
import Button from '../../Base/Button'
import { Container } from './index.module.less'

import IconEdit from 'assets/images/account/icon-edit.svg'

interface ProfessionalParams {
  className?: string | undefined;
  onClickEdit?: React.MouseEventHandler<HTMLElement> | undefined;
}

const Professional: React.FC<ProfessionalParams> = (params) => {
  const { user } = useContext(providerContext);
  const { className, onClickEdit } = params;

  return (
    <div className={classnames(Container, className)}>
      <h3 className="professional--title">职业信息<Button btnType="text" onClick={onClickEdit}><img src={IconEdit} alt="" className="icon-edit" /></Button></h3>
      <div className="form">
        <FormItem label="所在公司">
          <span>{user?.group_name}</span>
        </FormItem>
        <FormItem label="所在行业">
          <span>{user?.industry}</span>
        </FormItem>
        <FormItem label="职位">
          <span>{user?.position}</span>
        </FormItem>
      </div>
    </div>
  );
}

export default Professional;

interface FormItem {
  className?: string | undefined;
  label?: string | undefined;
}

export const FormItem: React.FC<FormItem> = (params) => {
  return (
    <div className="form-item">
      <label htmlFor="">{params.label}</label>
      <div className="form-value">
        {params.children}
      </div>
    </div>
  );
}