import React from "react"
import classnames from 'classnames'
import Button from '../../Base/Button'
import { Container } from "./index.module.less"

import MobileFootprintPng from 'assets/images/account/mobile-footprint.png'
import MobileRecommendPng from 'assets/images/account/mobile-recommend.png'

interface Params {
  className?: string;
}

const MobileCenterMenu: React.FC<Params> = (params) => {
  const { className } = params;

  return (
    <div className={classnames(Container, className)}>
      <Button btnType="text" href="/account/footprint.html">
        <img src={MobileFootprintPng} alt="" />
      </Button>
      <Button btnType="text" href="/account/recommend.html">
        <img src={MobileRecommendPng} alt="" />
      </Button>
    </div>
  );
}

export default MobileCenterMenu;