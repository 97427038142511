import React from "react"
import { Container } from './index.module.less'

interface RecommendCardParams {
  qrcode: string;
  bg?: string;
}

const RecommendCard: React.FC<RecommendCardParams> = (params) => {
  return (
    <div className={Container} style={{backgroundImage: `url(${params.bg})`}}></div>
  );
}

export default RecommendCard;