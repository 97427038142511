import React, { useState, useContext, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import Button from '../../Base/Button'
import { ButtonGroup } from '../../Base/Button'
import { providerContext } from '../../../components/Provider'
import { sencePhone } from 'utils/utils'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface LogoutProtocolProps {
  className?: string
  abandonLogout: React.MouseEventHandler<HTMLElement>
  nextStep: React.MouseEventHandler<HTMLElement>
  statues?: boolean
}

const LogoutProtocol = forwardRef(({ className, abandonLogout, nextStep }: LogoutProtocolProps, ref) => {
  const { user } = useContext(providerContext)
  const [flag, setFlag] = useState<boolean>(false)
  useImperativeHandle(ref, () => ({
    init: () => {
      setFlag(false)
    },
  }))
  const handleClick = () => {
    setFlag((flag) => !flag)
  }
  const handleGiveUpLogout = (e) => {
    setFlag(false)
    window && window.scrollTo({ top: 0, behavior: 'smooth' })
    abandonLogout(e)
  }
  const handleClickNextStep = (e) => {
    flag && nextStep(e)
  }
  return (
    <div className={classnames(styles.protocolContent, className)}>
      <h2 className="text-[20px] text-[#1F2D3D] leading-[30px] text-center font-medium">
        请您仔细阅读《神策数据账号注销重要提醒》
      </h2>
      <article className="text-[14px] mt-[20px] leading-[28px] text-[#475669]">
        <p>账号注销重要信息</p>
        <p>
          <span className="red_text">提交账号注销申请为不可逆操作。</span>
          为保证您的账号、财产安全，并保障正常的社会公共秩序，在您提交神策数据账号注销请求前，请先确认如下信息：
        </p>
        <div className="pl-[20px]">
          <p>
            1.
            提交账号注销请求的为您本人，或获得账号持有者本人合法授权的代理人/代表人。后一种情形下，代理人/代表人需将本提示及注销过程中的所有文件，告知账号持有者本人并确保本人已理解并统一前述文件的全部内容；
          </p>
          <p>
            2.
            您申请注销的账号处于稳定且安全的状态（例如：不存在被盗等风险，在最近三个月内没有进行换绑手机号等敏感信息变更的操作等）；
          </p>
          <p>3. 您在神策数据账号下的所有数据均已迁出及妥善备份，或您完全放弃保留、找回该等数据的权利；</p>
          <p>
            4.
            您提交账号注销申请，并非未来躲避正在或即将发生的纠纷诉讼，或规避主管部门的监管，或绕开法律法规的相关规定等不良意图。
          </p>
        </div>
      </article>
      <article className="text-[14px] mt-[30px] leading-[28px] text-[#475669]">
        <p>再次提示：</p>
        <p>
          <span className="red_text">提交账号注销申请为不可逆操作。</span>
        </p>
        <p>
          账号注销后，您将无法再次登录神策数据网站、神策学堂、小程序，无法访问个人中心、体验
          Demo、下载内容或进行其他依赖于账号权限进行的操作。
        </p>
        <p>您在该账号下的个人信息、Demo 数据、历史信息等均无法召回。</p>
      </article>
      <article className="text-[14px] mt-[40px] leading-[28px] text-[#475669]">
        <p className="check_content px-[10px]" onClick={handleClick}>
          <span className={classnames('inline-block w-[16px] h-[16px] check_protocol', { active: flag })}></span>
          <span className="check_text">
            我已阅读并同意上述《神策数据账号注销重要提醒》，提交申请后，我的账号 {sencePhone(user?.phone)}{' '}
            将被注销，包含的内容、数据和服务都不可再恢复
          </span>
        </p>
        <ButtonGroup className="mt-[20px]">
          <Button btnType="secondary" onClick={handleGiveUpLogout}>
            放弃注销
          </Button>
          <Button btnType="primary" disabled={!flag} onClick={handleClickNextStep}>
            下一步
          </Button>
        </ButtonGroup>
      </article>
    </div>
  )
})

export default LogoutProtocol
