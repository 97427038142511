import * as React from "react"
import classnames from "classnames";
import './index.module.less'

interface MobileListParams {
  className?: string;
}

const MobileList: React.FC<MobileListParams> = (params) => {
  const {
    className,
    children
  } = params;

  return (
    <div className={classnames("sd-mobile-list", className)}>
      {children}
    </div>
  );
}

export default MobileList;

interface MobileListItemParams {
  className?: string;
  title?: string;
  icon?: string;
  link?: string;
  onClick?: () => void;
}

export const MobileListItem: React.FC<MobileListItemParams> = (params) => {
  const {
    className,
    title,
    icon,
    onClick
  } = params;

  return (
    <div className={classnames("sd-mobile-list-item", className)} onClick={onClick}>
      <div className="sd-mobile-list-item--content">
        <div className="sd-mobile-list-item--left">
          <i className={classnames("sd-mobile-list-item--icon", icon)}></i>
        </div>
        <div className="sd-mobile-list-item--center">{title}</div>
        <div className="sd-mobile-list-item--right"></div>
      </div>
    </div>
  );
}