import React, { useState, useEffect, useMemo, memo, forwardRef, useImperativeHandle, useContext, useRef } from 'react'
import Button, { ButtonGroup, ButtonGroupAlign } from 'components/Base/Button'
// import { fetchLogout } from 'api/user'
import { providerContext } from '../../../components/Provider'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { sencePhone } from 'utils/utils'
import LogoutProtocol from '../LogoutProtocol'
import classnames from 'classnames'
import 'swiper/swiper-bundle.css'
import * as styles from './index.module.less'

interface LogoutProps {
  className?: string
  abandonLogout: React.MouseEventHandler<HTMLElement>
  onOpen: () => void
}
SwiperCore.use([Autoplay])
const Logout = forwardRef(({ className, abandonLogout, onOpen }: LogoutProps, ref) => {
  const { user } = useContext(providerContext)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [mySwiper, setMySwiper] = useState<SwiperCore>()
  const [statues, setStatues] = useState<boolean>()
  const myRef = useRef(null)
  const documentClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.location.reload()
  }

  // const handleLogout = async () => {
  // const res = await fetchLogout()
  // const { status } = res.data
  // if (status === 200) {
  // if (window.location.pathname.indexOf('/account/') >= 0) {
  //   window.location.href = '/'
  // } else {
  //   window.location.reload()
  // }
  // }
  // }
  useMemo(async () => {
    if (mySwiper?.activeIndex === 1) {
      try {
        await onOpen?.()
        await myRef.current?.init()
        window && window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (err) {
        console.log(err, 'err')
      }
    }
    if (mySwiper?.activeIndex === 2) {
      // handleLogout()
      document && document.addEventListener('click', documentClick, true)
    }
  }, [mySwiper?.activeIndex])
  useImperativeHandle(ref, () => ({
    fallback: (number) => {
      mySwiper?.slideTo(number)
    },
  }))
  useEffect(() => {
    return () => {
      document && document.removeEventListener('click', documentClick)
    }
  }, [])
  return (
    <div className={classnames(styles.container, className)}>
      <ul className="px-[20px] flex  text-[#CFCFCF] text-[14px] text-center font-medium leading-[32px]">
        <li className={classnames('flex-1  title_01', { active_01: activeIndex === 0 }, 'bg_100')}>重要提醒</li>
        <li className={classnames('flex-1  title_02', { active_02: activeIndex === 1 }, 'bg_100')}>注销确认 </li>
        <li className={classnames('flex-1  title_03', { active_03: activeIndex === 2 }, 'bg_100')}>注销完成</li>
      </ul>
      <Swiper
        onSwiper={(swiper) => {
          setMySwiper(swiper)
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        autoHeight={false}
        autoplay={false}
        allowTouchMove={false}
      >
        <SwiperSlide>
          <LogoutProtocol
            ref={myRef}
            abandonLogout={abandonLogout}
            statues={statues}
            nextStep={() => {
              mySwiper?.slideTo(1)
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-[200px]"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="text-center">
            <div className={classnames('inline-block text-left mt-[150px]', styles.succeedContent)}>
              <p className="text-[20px] font-medium text-[#1F2D3D] leading-[30px]  ">注销成功，感谢您访问神策数据</p>
              <p className="text-[12px]  text-[#999]">
                一段时间后您将无法使用 {sencePhone(user?.phone)} 账号访问神策数据官方网站
              </p>
            </div>
            <ButtonGroup className="mt-[70px]" align={ButtonGroupAlign.Center}>
              <Button btnType="primary" onClick={() => window && window.location.replace('/')}>
                返回官网首页
              </Button>
            </ButtonGroup>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
})

export default memo(Logout)
