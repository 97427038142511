import React, { useContext } from "react"
import { providerContext } from '../../../components/Provider'
import Button from '../../Base/Button'
import { Container } from './index.module.less'

import IconEdit from 'assets/images/account/icon-edit.svg'

interface UserInfoParams {
  avatar?: string;
  onClickEdit?: React.MouseEventHandler<HTMLElement> | undefined;
}

const UserInfo: React.FC<UserInfoParams> = (params) => {
  const { user } = useContext(providerContext);
  const {
    onClickEdit
  } = params;

  return (
    <div className={Container}>
      <div className="avatar">
        <img src={user?.user_avatar_url as string | undefined} alt="" />
      </div>
      <div className="content">
        <h3>欢迎回来，{user?.user_name}</h3>
        <Button btnType="text" onClick={onClickEdit}><img src={IconEdit} alt="" className="icon-edit" />编辑我的资料</Button>
      </div>
    </div>
  );
}

export default UserInfo;