import React, { useContext, useEffect } from 'react'
import classnames from 'classnames'
import { providerContext } from '../../../components/Provider'
import Button from '../../Base/Button'
import { sencePhone } from '../../../utils/utils'
import { Container } from './index.module.less'

import IconEdit from 'assets/images/account/icon-edit.svg'
import IconLogout from 'assets/images/account/icon_logout.png'
interface BaseInfoParams {
  className?: string | undefined
  avatar?: string | undefined
  onClickAvatar?: React.MouseEventHandler<HTMLElement>
  onClickUserName?: React.MouseEventHandler<HTMLElement>
  onClickMobile?: React.MouseEventHandler<HTMLElement>
  onClickLogout?: React.MouseEventHandler<HTMLElement>
}

const BaseInfo: React.FC<BaseInfoParams> = (params) => {
  const { user } = useContext(providerContext)
  const { onClickAvatar, onClickUserName, onClickMobile, onClickLogout } = params

  return (
    <div className={classnames(Container, params.className)}>
      <h3>基本信息</h3>
      <div className="form">
        <FormItem label="头像">
          <img src={user?.user_avatar_url as string} alt="avatar" className="avatar" />
          <Button btnType="text" size="small" onClick={onClickAvatar}>
            更换头像
          </Button>
        </FormItem>
        <FormItem label="姓名">
          <span>{user?.user_name}</span>
          <Button className="icon-button" btnType="text" size="small" onClick={onClickUserName}>
            <img src={IconEdit} alt="" className="icon-edit" />
          </Button>
        </FormItem>
        <FormItem label="手机号">
          <span>{sencePhone(user?.phone)}</span>
          <Button btnType="text" size="small" onClick={onClickMobile}>
            更换绑定
          </Button>
        </FormItem>
        <FormItem label="邮箱">
          <span>{user?.email}</span>
        </FormItem>
        <FormItem label="最近一次登录时间">
          <span>{user?.last_login_time}</span>
        </FormItem>
        <FormItem className="form-bottom">
          <Button btnType="text" size="small" href="https://www.sensorsdata.cn/compliance/privacy.html" target="_blank">
            点击查看隐私条款
          </Button>
        </FormItem>
      </div>
      <div className={classnames('logout-button', 'hidden lg:block lg:absolute h-[14px] top-[30px] right-[30px]')}>
        <Button btnType="text" size="small" className="text-[#99A9BF] !pr-[5px]" onClick={onClickLogout}>
          注销账号
        </Button>
        <img src={IconLogout} className="h-full" alt="" />
      </div>
    </div>
  )
}

export default BaseInfo

interface FormItemParams {
  className?: string | undefined
  label?: string | undefined
  style?: React.CSSProperties | undefined
}

const FormItem: React.FC<FormItemParams> = (params) => {
  return (
    <div className={classnames('form-item', params.className)} style={params.style}>
      <label>{params.label}</label>
      <div className="form-value">{params.children}</div>
    </div>
  )
}
