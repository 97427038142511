import React from "react"
import classnames from 'classnames'
import MobileList, { MobileListItem } from '../../Base/MobileList'
import { fetchLogout } from '../../../api/user'

interface Params {
  className?: string;
  onClickCertified?: () => void;
}

const MobileMenuList: React.FC<Params> = (params) => {
  const {
    className,
    onClickCertified
  } = params;

  const handleLogout = async () => {
    const res = await fetchLogout();
    const { status } = res.data;
    if(status === 200) {
      window.location.href = '/';
    }
  }

  return (
    <div className={classnames(className)}>
      <MobileList>
        {/* 签约客户登记表 移动端: 计划等到学堂pc版弄好后 再开放 */}
        {/* <MobileListItem icon="icon-sign" title="签约客户登记" onClick={onClickCertified}/> */}
        <MobileListItem icon="icon-logout" title="退出登录" onClick={handleLogout} />
      </MobileList>
    </div>
  );
}

export default MobileMenuList;