import * as React from "react"
import classnames from "classnames";
import './index.module.less'

interface PanelParams {
  className?: string | undefined;
  title?: string | undefined;
  subTitle?: string | undefined;
  style?: React.CSSProperties | undefined;
}

const Panel: React.FC<PanelParams> = (params) => {
  return (
    <div className={classnames("sd-panel", params.className)} style={params.style}>
      <div className="sd-panel--header">
        <h3 className="sd-panel--title">{params.title}</h3>
        {params.subTitle && <p className="sd-panel--sub-title">{params.subTitle}</p>}
      </div>
      <div className="sd-panel--body">
        {params.children}
      </div>
    </div>
  );
}

export default Panel;