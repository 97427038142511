import React, { ReactEventHandler, useState, useMemo, useRef } from 'react'
import { logoutUser } from 'api/user'
import classnames from 'classnames'
import Button, { ButtonGroup, ButtonGroupAlign } from 'components/Base/Button'
import { toast } from 'react-toastify'

import * as styles from './index.module.less'

interface LogoutDialogProps {
  className?: string
  visible: boolean
  onClose: ReactEventHandler<HTMLElement>
  onConfirmLogout: ReactEventHandler<HTMLElement>
}

const reasonArray = [
  { content: '不再需要相关服务', status: false },
  { content: 'Demo 体验不佳', status: false },
  { content: '内容不吸引我', status: false },
  { content: '营销骚扰太多', status: false },
  { content: '其他原因', status: false },
]

const LogoutDialog: React.FC<LogoutDialogProps> = ({ visible, className, onClose, onConfirmLogout }) => {
  const [reasonArr, setReasonArr] = useState(reasonArray)
  const [disabled, setDisabled] = useState<boolean>(false)

  // 点击注销原因
  const handleClick = (index) => {
    return () => {
      setReasonArr((reasonArr) => {
        const newReasonArr = JSON.parse(JSON.stringify(reasonArr))
        newReasonArr[index].status = !newReasonArr[index].status
        return newReasonArr
      })
    }
  }
  //点击取消
  const handleClickClose = (e) => {
    setReasonArr((reasonArr) => {
      const newReasonArr = JSON.parse(JSON.stringify(reasonArr))
      return newReasonArr.map((item) => ({ ...item, status: false }))
    })
    onClose(e)
  }
  // 文字提示
  // const tipTextVisible = useMemo(() => {
  //   const newArr = reasonArr.filter((item) => item.status)
  //   return newArr.length >= 2
  // }, [reasonArr])

  const handleClickLogout = async (e) => {
    setDisabled(true)
    const chooseReasonArray = reasonArr.filter((item) => item.status).map((child) => child?.content)

    try {
      const res = await logoutUser(chooseReasonArray)
      onConfirmLogout(e)
    } catch (err) {
      console.log(err, 'err--------')
      toast.error('注销失败', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } finally {
      setDisabled(false)
    }

    // setTimeout(() => {
    //   Promise.resolve(true).then((res) => {
    //     if (res) {
    //       onConfirmLogout(e)
    //       setDisabled(false)
    //     }
    //   })
    // }, 500)
  }
  return (
    <div
      className={classnames(' z-[2] fixed w-full h-full top-0 left-0 bg-[rgba(0,0,0,0.2)]', className, {
        hidden: !visible,
      })}
    >
      <div className="absolute w-[680px]  bg-[#fff] left-[50%] translate-x-[-50%] top-[240px] px-[25px] py-[25px]">
        <h3 className="text-[20px] font-medium leading-[30px] text-[#1F2D3D]">
          <img
            className="w-[20px] h-[20px] align-middle translate-y-[-2px]"
            src={require('assets/images/account/exclamation_point.png').default}
            alt=""
          />
          <span className="ml-[5px]">确认注销吗</span>
        </h3>
        <p className="mt-[18px] text-[14px] text-[#E01E05] leading-[20px]">
          重要提醒：确认注销后，系统将自动执行注销进程，您无法自行终止，在此期间，请不要使用此账号进行任何操作，以免注销失败。注销后，您在神策数据的相关信息都将被清空无法找回，请慎重。
        </p>
        <p className="mt-[36px] text-[14px] text-[#475669] leading-[20px]">
          如果可以，请您告诉我们注销的原因（多选）：
        </p>
        <div className={classnames(styles.reason)}>
          {reasonArr.map((item, index) => {
            return (
              <div
                key={index}
                className={classnames('reason_item', { content_active: item?.status })}
                onClick={handleClick(index)}
              >
                {item?.content}
              </div>
            )
          })}
        </div>
        <div className="mt-[40px]">
          <ButtonGroup align={ButtonGroupAlign.Right}>
            <Button disabled={disabled} btnType="secondary" onClick={handleClickClose}>
              取消
            </Button>
            <Button disabled={disabled} btnType="primary" onClick={handleClickLogout}>
              确认注销
            </Button>
          </ButtonGroup>
        </div>
        <div className={classnames('absolute text-[12px] text-[#999] bottom-[35px] left-[25px]')}>
          十分抱歉给您带来了不好的使用体验，神策数据期待与您再次相遇
        </div>
      </div>
    </div>
  )
}

export default LogoutDialog
