import React, { useContext } from "react"
import Button from '../../Base/Button'
import { providerContext } from '../../../components/Provider'
import { Container } from './index.module.less'

import avatarPng from 'assets/images/account/avatar.png';

const UserInfoMobile: React.FC = (params) => {
  const { user } = useContext(providerContext);

  return (
    <div className={Container}>
      <img src={user?.user_avatar_url || avatarPng} alt="" className="mobile-user-info--avatar" />
      {user?.user_name ? <span className="mobile-user-info--username">{user.user_name}</span> : <span className="mobile-user-info--username">点击登录</span>}
      {user?.user_id && <Button btnType="text" className="mobile-user-info--homepage" href="/account/userCenter.html">个人主页<i className="icon-arrow"></i> </Button>}
    </div>
  );
}

export default UserInfoMobile;